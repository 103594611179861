import {EndpointResort, Resort} from "../domain/Resort.model";
import {createAdapterCity} from "../../city/adapter/city.adapter";

export const createAdapterResort = (resort: EndpointResort) => {
    const amenities = resort.amenities ?? [];
    const formattedResort: Resort = {
        id: resort.id,
        slug: resort.slug,
        name: resort.name,
        email: resort.email,
        phone: resort.phone,
        description: resort.description,
        address: resort.address,
        privateMPKey: resort.privateMPKey,
        publicMPKey: resort.publicMPKey,
        checkIn: resort.checkIn,
        checkOut: resort.checkOut,
        geoLocation: resort.geoLocation,
        city: createAdapterCity(resort.city),
        reservationMap: resort.reservationMap,
        multimedia: resort.multimedia,
        amenities: amenities,
        enabled: resort.enabled,
        mode: resort.mode,
        resortType: resort.resortType,
        images: resort.images,
        settings: resort.settings
    };
    return formattedResort;
}