import styled from "styled-components";
import {NavLink} from "react-router-dom";

export const StyledTopbarTabs = styled.div`
    position: relative;
    width: 100%;
    height: 100px;
    display: flex;
    gap: 1rem;
    align-items: flex-end;
    justify-content: flex-start;
    // border-bottom: 2px solid ${(props) => props.theme.layout.sidebar.menuItemIconColorActive};
    padding: 0 20px;
`

export const StyledTopbarTabsBack = styled(NavLink)`
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translateY(-50%);
    color: ${(props) => props.theme.layout.sidebar.menuItemIconColorActive};
    &:hover {
        font-weight: bold;
    }
`

interface StyledTabProps {
    to: string;
    $showname?: boolean;
}

export const StyledTab = styled(NavLink)<StyledTabProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 10px;
    color: ${(props) => props.theme.layout.sidebar.menuItemIconColorActive};
    font-size: 1rem;
    opacity: 0.5;
    &.active {
        opacity: 1;
        font-weight: 600;
    }
`

export const StyledTabIcon = styled.img`
    width: 75px;
    height: 50px;
    object-fit: contain;
`

export const StyledTabDefaultIcon = styled.div`
    width: 75px;
    height: 50px;
    object-fit: contain;
    object-position: center;
    svg {
        width: 100%;
        height: 100%;
    }
`